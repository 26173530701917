/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import home from './home'
import administration from './administration'
import orders from './orders'
import vehicles from './vehicles'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import cashierManager from "@/navigation/vertical/cashierManager";
import scale from "@/navigation/vertical/scale";
import pos from "@/navigation/vertical/pos";
import manager from "@/navigation/vertical/manager";

// Array of sections
// export default [...dashboard, ...home, ...administration, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [...cashierManager, ...pos, ...manager]
// export default [...home, ...vehicles, ...orders, ...administration]
