export default [
  {
    header: 'Administración',
    // action: 'read',
    // resource: 'Auth',
  },
  {
    title: 'Inventario',
    icon: 'ArchiveIcon',
    route: 'inventory',
    // action: 'read',
    // resource: 'Inventory'
    // route: 'order-preview',
    // tag: '2',
    // tagVariant: 'light-warning',
  },
  {
    title: 'Lista P. Compra',
    icon: 'ShuffleIcon',
    route: 'purchase-price-lists',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Lista P. Venta',
    icon: 'ShuffleIcon',
    route: 'sell-price-lists',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Gastos e Ingresos',
    icon: 'ShuffleIcon',
    route: 'view-expenses-and-incomes',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Productos',
    icon: 'ShuffleIcon',
    route: 'company-products',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Prov. de compra',
    icon: 'ShuffleIcon',
    route: 'company-providers',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Prov. de venta',
    icon: 'ShuffleIcon',
    route: 'seller-company-providers',
    // action: 'read',
    // resource: 'PublicPrices'
  },
  {
    title: 'Ordenes de venta',
    icon: 'ShuffleIcon',
    route: 'sell-orders',
    // action: 'read',
    // resource: 'PublicPrices'
  }
]
