export default [
  {
    header: 'POS',
    // action: 'read',
    // resource: 'Auth',
  },
  {
    title: 'Tickets de compra',
    icon: 'LayersIcon',
    route: 'view-purchase-tickets',
    // action: 'read',
    // resource: 'DailyCut'
    // tag: '2',
    // tagVariant: 'light-warning',
  },
  {
    title: 'Corte diario',
    icon: 'LayersIcon',
    route: 'dailyCut',
    // action: 'read',
    // resource: 'DailyCut'
  },
  {
    title: 'Punto de Venta',
    icon: 'AnchorIcon',
    route: 'scale',
    // action: 'read',
    // resource: 'Scale',
  },
]
