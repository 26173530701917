export default [
  {
    header: 'Administrador de cajero',
    // action: 'read',
    // resource: 'Auth',
  },
  {
    title: 'Balance de Cajeros',
    icon: 'HomeIcon',
    route: 'cashier-balances',
    // action: 'read',
    // resource: 'CashierBalance',
    // tag: '2',
    // tagVariant: 'light-warning',
  },
  {
    title: 'Reporte de cajeros',
    icon: 'LayersIcon',
    route: 'cashier-balance-reports',
    // action: 'read',
    // resource: 'DailyCut'
    // tag: '2',
    // tagVariant: 'light-warning',
  },
  {
    title: 'Cobrar ticket',
    icon: 'HomeIcon',
    route: 'verifyTicket',
    // action: 'read',
    // resource: 'Cashier',
    // tag: '2',
    // tagVariant: 'light-warning',
  },
]
